<template>
  <div>
    <CCardHeader>
      <span><strong>İş Ortakları Sıralama</strong></span>
    </CCardHeader>

    <CContainer class="pt-5">
      <CRow>
        <CCol md="12">
          <CCard>

            <CCardBody v-if="items.length > 0">
              <CRow class="mb-3">
                <CCol col="8">
                  <strong> İş Ortağı</strong>
                  <hr />
                </CCol>
                <CCol col="2" class="text-right">
                  <strong> Durum</strong>
                  <hr />
                </CCol>
                <CCol col="2" class="text-right">
                  <strong> Eylem</strong>
                  <hr />
                </CCol>
              </CRow>
            
              <draggable v-model="items" group="people" @start="drag = true" @end="drag = false">
                <CRow v-for="(item, i) in items" :key="i" class="mb-3">

                  <CCol col="8" class="d-flex align-items-center">
                   
                    <font-awesome-icon size="lg" :icon="['fas', 'arrows-alt']" class="mr-4 grey" />
                    <CSelect :value.sync="item.value" :options="vendors" />

                  </CCol>
                  <CCol col="2" class="d-flex align-items-center justify-content-end">
                    <CSelect class="mb-0" :value.sync="item.status" :options="status" />
                  </CCol>
                  <CCol col="2" class="d-flex align-items-center justify-content-end">
                    <CButton color="danger" size="lg" @click="deleteItem(item)">Sil</CButton>
                  </CCol>
                </CRow>
              </draggable>
            </CCardBody>
            <CCardFooter align="right">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <strong v-if="items.length == 0"> Kayıtlı bir veri yok </strong>
                </div>
                <div>
                  <CButton color="info" class="mr-3" size="lg" @click="addItem()">Ekle</CButton>
                  <CButton color="success" size="lg" @click="saveItem()">Kaydet</CButton>
                </div>
              </div>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>



  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import draggable from "vuedraggable";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
export default {
  components: {
    draggable,
  },

  data() {
    return {
      vendors: [],
      items: [],
      editedItem: {
        value: "",
        status: "active",
      },
      defaultItem: {
        value: "",
        status: "active",
      },
      status: [
        {
          value: "active",
          label: "Aktif",
        },
        {
          value: "passive",
          label: "Pasif",
        },
      ],
    };
  },
  created() {
    this.data();
    this.getVendors()
  },
  methods: {
    addItem() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
      this.items.push(this.editedItem);
    },
    deleteItem(item) {
      var index = this.items.indexOf(item);
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu ögeyi silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.items.splice(index, 1);
          }
        });
    },
    async saveItem() {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + `admin/singular?slug=VENDOR_ORDER`,
          {
            items: this.items,
          },
          { withCredentials: true }
        );
        Vue.swal.fire({
          icon: "success",
          showCloseButton: true,
          text: "Kaydetme Başarılı",
        });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: "Bir hata oluştu.",
        });
      }
    },
    async data() {
      try {
        var result = await axios.get(
          process.env.VUE_APP_API_URL + `admin/singular?slug=VENDOR_ORDER`,

          { withCredentials: true }
        );

        this.items = result.data.data.items;
      } catch (error) { }
    },
    getVendors() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor")
        .then((response) => {
          var array = response.data.vendorList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].brand,
              value: array[index].image,

            };
            result.push(item);
          }

          this.vendors = result

        });
    },
  },
};
</script> 

<style lang="scss" scoped>
.sub-header {
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: rgb(228, 228, 228);
  border-color: #d8dbe0;
  color: #000 !important;
}
</style>